<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('ledger_detail_jsxx', { id: $route.query.id })">结算信息</span>
      <span @click="toUrl('ledger_detail_glqd', { id: $route.query.id })">关联渠道</span>
      <span @click="toUrl('ledger_detail_dzzb', { id: $route.query.id })">电子账簿</span>
      <span @click="toUrl('ledger_detail_cpxx', { id: $route.query.id })">产品信息</span>
      <span @click="toUrl('ledger_detail_bccl', { id: $route.query.id })">补充材料</span>
    </div>
    <div v-auth="'ELECTRLED:MCHCOM:MCHLIST:BASE'">
      <div class="desc">
        <h5 class="jsxx">基本信息</h5>
        <div>
          <el-button
            v-auth="'ELECTRLED:MCHCOM:MCHLIST:BASE/EDIT'"
            @click="toUrl('ledger_detail_jbxx_edit',{ id: $route.query.id } )"
            class="editBt"
            type="primary"
          >编辑</el-button>
        </div>
      </div>
      <div class="list clear">
        <ul class="listBox">
          <li><span>商户名称</span><i class="hidden1">
              <el-tooltip class="item" effect="light" :content="detail.mchName"  placement="top" >
                <span>{{ detail.mchName }}</span>
              </el-tooltip>
            </i></li>
          <li><span>商户类型</span><i class="hidden1">{{ mchTypeLists[detail.mchType] }}</i></li>
          <li><span>商户编号</span><i class="hidden1">{{ tabForm.mchIdHj }}</i></li>
          <li><span>账户编号</span><i class="hidden1">{{ tabForm.mchIdHj }}</i></li>
          <li><span>行业类别</span>
            <i class="hidden1">
               <el-tooltip  class="item"  effect="light"  :content="detail.bussinessType"  placement="top">
                <span>{{ detail.bussinessType }}</span>
              </el-tooltip>
            </i>
          </li>
          <li><span>营业执照编号</span><i class="hidden1">{{ detail.licenceNo }}</i></li>
          <li><span>营业执照注册地址</span>
            <i class="hidden1">
              <el-tooltip  class="item"  effect="light"  :content="detail.companyAddress"  placement="top">
                <span>{{ detail.companyAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li><span>营业执照有效期</span>
            <i class="hidden1">
              <h5 v-if="!detail.licenceBegin && !detail.licenceEnd"></h5>
              <h5 v-else class="hidden1">
                {{ detail.licenceBegin }}
                <span v-if="detail.licenceBegin && detail.licenceEnd">至</span>
                {{ detail.licenceEnd == "forever" ? "长期": detail.licenceEnd }}
              </h5>
            </i>
          </li>

          <li><span>经营地址</span>
            <i class="hidden1">
              {{ detail.registryProvince }}{{ detail.registryCity ? "-" + detail.registryCity : ""
              }}{{ detail.registryDistrict ? "-" + detail.registryDistrict : "" }}
            </i>
          </li>
          <li><span>经营详细地址</span>
            <i class="hidden1">
              <el-tooltip  class="item"  effect="light"  :content="detail.companyAddress"  placement="top">
                <span>{{ detail.registryAddress }}</span>
              </el-tooltip>
            </i>
          </li>

          <li><span>营业执照照片</span>
            <i class="hidden1">
              <a v-if="detail.licencePicture" href="javascript:;" @click="showImg(detail.licencePicture)">点击预览</a>
            </i>
          </li>
          <li><span>法人姓名</span><i class="hidden1">{{ detail.legalPersonName }}</i></li>
          <li><span>法人手机号码</span><i class="hidden1">{{ detail.legalPersonPhone }}</i></li>
          <li><span>法人身份证号码</span><i class="hidden1">{{ detail.idcardNo }}</i></li>
          <li><span>法人证件有效期</span>
            <i class="hidden1">
              <h5 v-if="!detail.legalPersonCemStartDate && !detail.legalPersonCemEndDate"></h5>
              <h5 v-else class="hidden1">
                {{ detail.legalPersonCemStartDate }}
                <span v-if="detail.legalPersonCemStartDate && detail.legalPersonCemEndDate">至</span>
                {{ detail.legalPersonCemEndDate == "forever" ? "长期": detail.legalPersonCemEndDate }}
              </h5>
            </i>
          </li>
          <li><span>法人身份证人像照片</span><i class="hidden1">
              <a  v-if="detail.idcardReversePicture"  href="javascript:;"  @click="showImg(detail.idcardReversePicture)">点击预览</a>
            </i></li>
          <li><span>法人身份证国徽照片</span><i class="hidden1">
              <a v-if="detail.idcardNormalPicture"  href="javascript:;" @click="showImg(detail.idcardNormalPicture)" >点击预览</a>
            </i></li>
          <li><span>开户许可证编号</span><i class="hidden1">{{ detail.accountOpeningLicenceNo }}</i></li>
          <li><span>开户许可证照片</span><i class="hidden1">
              <a  v-if="detail.accountOpeningLicense"  href="javascript:;"  @click="showImg(detail.accountOpeningLicense)">点击预览</a>
            </i></li>
          <li><span>创建时间</span><i class="hidden1">{{ detail.createTime }}</i></li>
          <li><span>审核时间</span><i class="hidden1"> {{ tabForm.updateTime ? tabForm.updateTime : "" }}</i></li>
          <li><span>最后修改时间</span><i class="hidden1">{{ detail.updateTime }}</i></li>
          <li><span>最后修改人</span><i class="hidden1">{{ detail.lastUpdatedUser }}</i></li>
        </ul>
      </div>

      <div class="desc">
        <h5 class="jsxx">进件信息</h5>
      </div>
      <div class="tab1" style="margin-bottom: 63px;min-height: auto;">
        <table class="tab">
          <tr class="tab_title">
            <td>
              <p>进件单号</p>
            </td>
            <td>
              <p>商户编号</p>
            </td>
            <td>
              <p>审核状态</p>
            </td>
            <td>
              <p>审核类型</p>
            </td>
            <td>
              <p>错误描述</p>
            </td>
            <td>
              <p>商户状态</p>
            </td>
            <td>
              <p>签约状态</p>
            </td>
            <td>
              <p>操作</p>
            </td>
          </tr>
          <tr v-for="(v, i) in tabdata" :key="i">
            <td>
              <p>{{ v.mchOrderNo}}</p>
            </td>
            <td>
              <p>{{ v.mchIdHj  }}</p>
            </td>
            <td>
              <p>{{ shenheStatus[v.status] }}</p>
            </td>
            <td>
              <p v-if="v.auditType == 1">系统审核</p>
              <p v-if="v.auditType == 2">人工审核</p>
            </td>
            <td>
              <p>{{ v.bizMsg }}</p>
            </td>
            <td>
              <p>{{ mchStatusData[v.mchStatus] }}</p>
            </td>
            <td>
              <p>{{ signStatusData[v.signStatus] }}</p>
            </td>
            <td>
              <p v-if="i == 0">
                <a href="javascript:;" @click="showTab(v,'syncInfoShow')" class="lianjie">同步信息</a>
                <a href="javascript:;" @click="showTab(v,'mchSignShow')" class="lianjie">商户签约</a>
              </p>
            </td>
          </tr>
        </table>
      </div>

      <!-- 注：仅当进件状态为未进件或已驳回的时候才能触发进件，否则置灰  （审核状态为空或者已驳回,才能进件）-->
      <div class="status_desc">
          <span>进件状态</span>
          <span class="status" v-if="tabdata==null || tabdata.length<=0">未进件</span>
          <span class="status" v-else>{{ shenheStatus[tabForm.status] }}</span>
          <el-button
            v-auth="'ELECTRLED:MCHCOM:MCHLIST:BASE/APPLY'"
            class="returnBt"
            :disabled="inGoodDisabled "
            :class="inGoodClass ? 'active' :'' "
            @click="inGoods()"
            type="primary"
            >进件</el-button>
      </div>

      <el-dialog title="同步信息" :visible.sync="syncInfoShow" width="490px" @close="onClose" :close-on-click-modal="false">
        <ul class="overAccount_ul_line">
          <li class="ul_left" style="display: flex;align-items: center;width: 300px;margin: auto;">
            <div class="overAccount_ul_title"><i>*</i>选择类型</div>
              <el-select
              class="inputs"
              v-model="checkStatusType"
              placeholder="选择类型"
              tabindex="5"
              style="height:40px;"
            >
              <el-option
                v-for="(v, i) in checkStatusTypeData"
                :key="i"
                :label="v.title"
                :value="v.value"
                :disabled="v.disabled"
              ></el-option>
            </el-select>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button class="addBt" :disabled="buttonFlag" @click="syncInfoBtn"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-dialog title="商户签约" :visible.sync="mchSignShow" width="490px" :close-on-click-modal="false">
        <ul class="overAccount_ul_line">
          <li class="ul_left" style="display: flex;align-items: center;justify-content: center;">
            <div class="overAccount_ul_title" style="width:auto">签约商户：</div>
            <div>{{tabForm.mchIdHj}}</div>
          </li>
          <li style="margin: 0 auto;">
            <div class="copyBtn" @click="copyLink()">复制签约链接</div> 
      		</li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <input type="text" ref="copyLinkInput" id="copyLinkInput" style="width:10px;opacity: 0;">
          <el-button class="addBt" :disabled="buttonFlag" @click="mchSignBtn">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 点击预留 -->
      <div
        class="cover_view"
        v-if="showImgUrl !== ''"
        @click="showImgUrl = ''"
      >
        <a
          :href="showImgUrl"
          target="_blank"
        >
          <img
            :src="showImgUrl"
            alt="加载失败"
          />
        </a>
      </div>
      <div
        class="cover_view"
        v-if="showImgUrls.length > 0"
        @click="showImgUrls = []"
      >
        <i
          v-if="showImgUrlIndex > 0"
          class="el-icon-arrow-left left"
          @click.stop="showImgUrlIndex--"
        ></i>
        <i
          v-if="showImgUrlIndex < showImgUrls.length - 1"
          class="el-icon-arrow-right right"
          @click.stop="showImgUrlIndex++"
        ></i>
        <a
          :href="showImgUrls[showImgUrlIndex].url"
          target="_blank"
        >
          <img
            :src="showImgUrls[showImgUrlIndex].url"
            alt="加载失败"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { ledgerDetail, ledgerResend , mchApply,mchApplyQuery,mchApplySign } from "@/api/user/shopManager.js";
import industry from "@/utils/industry.json"
import address from "@/utils/address.json";
export default {
  data() {
    return {
      address,
      detail: {},
      mchTypeLists: {
        "": "",
        ENTERPRISE: "企业",
        INDIVIDUAL_BUSINESS: "个体工商户",
      },
      licenceType: [
        "统一社会信用代码证书",
        "有偿服务许可证（军队医院适用）",
        "医疗机构执业许可证（军队医院适用）",
        "企业营业执照（挂靠企业的党组织适用）",
        "组织机构代码证（政府机关适用）",
        "社会团体法人登记证书",
        "民办非企业单位登记证书",
        "基金会法人登记证书",
        "慈善组织公开募捐资格证书",
        "农民专业合作社法人营业执照",
        "宗教活动场所登记证",
        "营业执照",
        "营业执照号码（多合一）",
        "事业单位法人证书",
        "其他证书/批文/证明",
      ],
      microType: {
        MICRO_TYPE_STORE: "店铺场所",
        MICRO_TYPE_MOBILE: "流动经营/便民服务",
        MICRO_TYPE_ONLINE: "线上商品/服务交易",
      },
      checkStatus: {
        WAIT: "待审核",
        PASS: "已通过",
        REJECT: "已驳回",
      },
      activateStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      showImgUrl: "",
      showImgUrls: [], //查看多个图片
      showImgUrlIndex: 0, //查看多个图片切换
      tabdata:{},
      buttonFlag:false,
      syncInfoShow:false,//同步弹窗
      checkStatusType:"",
      checkStatusTypeData:[ 
        {
          title: "审核结果",
          value: "1",
          disabled:false,
        },
        {
          title: "签约结果",
          value: "2",
          disabled:false,
        },
      ],
      mchSignShow:false,//商户签约
      copyLinkTitle:"",
      industrysType:industry,
      shenheStatus:{//审核状态
        "P1000":"已创建",
        "P2000":"审核中",
        "P3000":"已驳回",
        "P4000":"审核通过",
      },
      mchStatusData:{
        "P1000":"待签约",
        "P2000":"激活",
        "P3000":"冻结",
      },
      signStatusData:{
        "P1000":"待签署",
        "P2000":"商户已签署",
        "P3000":"已生效",
        "P4000":"商户撤销",
        "P5000":"平台撤销",
        "P6000":"已拒签",
      },
      tabForm:{},//取第一条数据     
      inGoodClass:false,//进件按钮样式
      inGoodDisabled:true,//进件按钮是否能点击
      signUrl:"",//复制链接
    };
  },
  created() {
    if (this.$route.query.id) {
      this.getDetail();
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      ledgerDetail(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.detail = res.resultData.mchDetail;
          this.tabdata = res.resultData.mchProfileApply;
          if(this.tabdata.length>0){//进件状态
            this.tabForm = res.resultData.mchProfileApply[0];
            if(this.tabForm.status != null && this.tabForm.status != '' && this.tabForm.status != 'P3000'){
              this.inGoodClass = true;
            }
            if(this.tabForm.status == 'P3000'){
              this.inGoodDisabled = false;
            }
          }else{
            this.inGoodDisabled = false;
          }         
          this.industrysType.forEach(item => { //行业类型
            if(item.id==this.detail.bussinessType){
              this.detail.bussinessType = item.name;
            }
          });
          this.getAddress();
        }
      });
    },
    //获取地址
    getAddress() {
      let province = this.address.find(
        (v) => v.provinceCode == this.detail.registryProvince
      ).citys;
      this.detail.registryProvince = this.address.find(
        (v) => v.provinceCode == this.detail.registryProvince
      ).province;
      let city = province.find((v) => v.cityCode == this.detail.registryCity).countys;
      this.detail.registryCity = province.find(
        (v) => v.cityCode == this.detail.registryCity
      ).city;
      this.detail.registryDistrict = city.find(
        (v) => v.countyCode == this.detail.registryDistrict
      ).county;
    },   
    showTab(value, active) {
      this.tabForm = value;
      if (active == "syncInfoShow")  {
        this[active] = true;
      }
      if (active == "mchSignShow") {
        //审核通过和商户编号不为空可签约
        if(this.tabForm.status != 'P4000' && this.tabForm.mchIdHjs != ""){
          this.$message.error("审核通过和商户编号不为空同时满足，可签约");
          return false;
        }
        mchApplySign(this.tabForm.id).then((res) => {
          if (res.resultStatus) {
            this.signUrl = res.resultData.signUrl;
            this.mchSignShow = true;
          }
        });
        this[active] = false;
      } 
    },
    //同步信息
    syncInfoBtn(){
      let data = {
        type:this.checkStatusType,
      }
      if (!this.checkStatusType) {
        this.$message.error("类型必选");
        return false;
      }
      mchApplyQuery(this.tabForm.id , data).then((res) => {
        if (res.resultStatus) {
          this.$message.success("同步请求成功");
          this.onClose();
        }
        this.getDetail();
      });
    },
    onClose(){
      this.checkStatusType = "";
      this.syncInfoShow = false;
    },    
    //商户签约
    mchSignBtn(){
      this.mchSignShow = false;
      this.getDetail();
    },
    //复制签约链接
    copyLink(){
      this.copyLinkTitle = this.signUrl;
      let inputs=document.getElementById("copyLinkInput") ;   
          inputs.value=this.copyLinkTitle ;
          inputs.select() //选中节点
      let actions =  document.execCommand("Copy") //指定复制命令(返回的是一个boolean类型)
      if(actions == true){
          this.$message.success("复制成功");
      }else{
          this.$message.error("复制失败");
      }
    },
    // 进件
    inGoods() {
      if(this.tabForm.status != null && this.tabForm.status != '' && this.tabForm.status != 'P3000'){
          this.$message.error("进件状态为未进件或已驳回时，才能触发进件");
          return false;
      }
      this.$confirm("是否进行进件", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          mchApply(this.$route.query.id).then((res) => {
            if (res.resultStatus) {
              this.$message.success("进件成功");
            }
            this.getDetail();
          });
        })
        .catch(() => {
          console.log("取消");
        });
    },
    // 浏览单个图片
    showImg(val) {
      this.showImgUrl = val;
    },
    // 浏览多个图片
    showImgs(val) {
      this.showImgUrls = val ? JSON.parse(val) : [];
      this.showImgUrlIndex = 0;
    },

    // 重发邮件
    reEmail() {
      ledgerResend(this.$route.query.id).then((res) => {
        if (res.resultStatus) {
          this.$message.success("重发邮件请求成功");
        }
      });
    },
    // 跳转页面
    toUrl(name, query) {
      this.$router.push({
        name: name,
        query: {
          ...query,
        },
      });
    },
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
@import "../../../assets/css/detail.css";
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  text-align: center;
  height: 33px;
}
.status_desc {
  height: 32px;
  margin: 0 0px 20px 0px;
  padding: 10px 30px 10px 35px;
  background-color: #f4f5f6;
  font-size: 16px;
  line-height: 32px;
  color: var(--label);
}

.status_desc .status {
  margin-left: 56px;
  color: var(--title);
}

.status_desc .overBt {
  float: right;
  width: 60px;
  height: 32px;
  margin-left: 10px;
  padding: 0;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
  border-radius: 3px;
}

.status_desc .overBt.active {
  background: var(--themeColor);
  border-color: var(--themeColor);
}

.status_desc .returnBt {
  float: right;
  width: 60px;
  height: 32px;
  padding: 0;
  margin-left: 10px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(215, 218, 226, 1);
  border-radius: 3px;
  font-size: 12px;
  color: var(--title);
}

.status_desc .returnBt.active {
  background-color: #aaaaaa;
  border-color: #aaaaaa;
  font-size: 12px;
  color: #ffffff;
}

.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}

.cover_view .left {
  position: absolute;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}

.cover_view .right {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 26px;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul_line {
  overflow: hidden;
}
.overAccount_ul_line .overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}
.overAccount_ul_line .ul_left{
  display: flex;
    align-items: center;
    width: 300px;
    margin: auto;
}
.overAccount_ul_line .overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
  width: 120px;
}
.overAccount_ul_line .overAccount_ul .el-input,
.overAccount_ul_line .overAccount_ul .el-select {
  margin-bottom: 7px;
  width: calc(100% - 120px);
}
.dialog-footer{
  text-align: center;
    margin-top: 30px;
    width: 100%;
    display: block;
}
.copyBtn{
width: 90px; height: 30px;color: var(--title); font-size: 12px;text-align: center; margin: auto;line-height: 32px;border: 1px solid #dce0e6; line-height: 30px; border-radius: 5px; margin-top: 10px; cursor: pointer;
}
</style>
